import "./ShowMore.scss";
import { useState, useEffect } from "preact/hooks";
import { useRef } from "preact/compat";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  maxHeight: number;
  showMoreCaption: string;
  showLessCaption: string;
}

export default function ShowMore({ children, maxHeight, showMoreCaption, showLessCaption }: Props) {
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState(maxHeight);
  const [isUse, setIsUse] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return function () {};
    }
    if (show) {
      const { height: value } = ref.current.getBoundingClientRect();
      setHeight(value);
    } else {
      setHeight(maxHeight);
    }
  }, [show, ref.current]);

  useEffect(() => {
    function calcWidth() {
      setIsUse(window.innerWidth < 1240);
    }
    calcWidth();
    window.addEventListener("resize", calcWidth);
    return () => window.removeEventListener("resize", calcWidth);
  }, []);

  return isUse ? (
    <div
      className={classNames(
        "show-more--wrapper",
        { ["show-more__open"]: show },
        { ["show-more__close"]: !show },
      )}
    >
      <div className="show-more--container" style={{ maxHeight: `${height}px` }}>
        <div ref={ref}>{children}</div>
      </div>
      <button className="show-more__button" onClick={() => setShow((v) => !v)}>
        {show ? showLessCaption : showMoreCaption}
      </button>
    </div>
  ) : (
    <>{children}</>
  );
}
